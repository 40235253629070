<template>
  <div>
    <!--update rate-->
    <div class="p-1 rounded shadow" >
      <ValidationObserver ref="form">
        <div>
          <h4>النسب</h4>
          <b-row>
            <b-col cols="12" md="6" lg="6">
              <ek-input-text
                :rules="[
                  {
                    type: 'required',
                    message: 'نسبة نقطة البيع مطلوبة ',
                  },
                ]"
                name="sellPointRate"
                placeholder="أدخل نسبة نقطة البيع"
                label="نسبة نقطة البيع "
                v-model="dashDefaultValues.sellPointRate"
              >
              </ek-input-text>
            </b-col>
            <b-col cols="12" md="6" lg="6">
              <ek-input-text
                :rules="[
                  {
                    type: 'required',
                    message: '  سعر الكود مطلوب ',
                  },
                ]"
                name="sellPointRate"
                placeholder="أدخل   مدة الكود"
                label="   المدة الافتراضية لإنتهاء الرمز (بالايام) "
                v-model="dashDefaultValues.codePeriod"
              >
              </ek-input-text>
            </b-col>
          </b-row>
        </div>
      </ValidationObserver>
      <div class="w-100 d-flex justify-content-between mt-2">
        <div>
          <b-button variant="primary" @click="submit">تعديل</b-button>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <b-row>
        <!--tabel year-->
        <b-col cols="12" md="6" lg="6">
          <ek-table
            :items="settingDto.years"
            :columns="yearCols"
            selectedLabel="id"
            title="السنوات"
            isPlus
            @plus="setYearDialogForm()"
            @details="setYearDialogForm($event)"
            @delete-selected="deleteYear"
          >
          </ek-table>
        </b-col>

        <!-- tabel semester-->
        <b-col cols="12" md="6" lg="6">
          <ek-table
            :items="settingDto.semesters"
            :columns="semesterCols"
            selectedLabel="id"
            title="الفصول"
            isPlus
            @plus="setSemesterDialogForm()"
            @details="setSemesterDialogForm($event)"
            @delete-selected="deleteSemester"
          >
          </ek-table>
        </b-col>
      </b-row>

      <!--form add & update teacher-->
      <div class="d-flex justify-content-end mb-1">
        <ek-dialog
          :isEdit="!!teacherDto.id"
          :title="teacherDto.id ? 'تعديل  استاذ' : 'اضافة استاذ '"
          @delete="deleteT(teacherDto.id)"
          btnText=" استاذ جديد"
          ref="dialogTeacher"
          placeholder="ابحث عن استاذ محدد"
          @ok="submitForm"
          @close="reset"
        >
          <template #body>
            <validationObserver ref="teacherForm">
              <ek-input-text
                name="name "
                placeholder="ادخل اسم الاستاذ"
                label="الاسم الكامل "
                :rules="[
                  {
                    type: 'required',
                    message: 'اسم الاستاذ مطلوب',
                  },
                ]"
                v-model="teacherDto.name"
              ></ek-input-text>

              <ek-input-text
                type="number"
                name="phone "
                placeholder="ادخل رقم الموبايل "
                label="رقم الموبايل "
                :rules="[
                  {
                    type: 'required',
                    message: 'رقم الموبايل مطلوب',
                  },
                ]"
                v-model="teacherDto.phoneNumber"
              ></ek-input-text>

              <ek-input-text
                name="password "
                placeholder="ادخل كلمة السر  "
                label="كلمة السر"
                v-model="teacherDto.password"
              ></ek-input-text>
            </validationObserver>
          </template>
        </ek-dialog>
      </div>

      <!--tabel teacher-->
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <ek-table
            :items="settingDto.teachers"
            @delete-selected="deleteTeacher"
            :columns="teacherCols"
            @details="teacherDetails"
          >
            <template slot="items.dateCreated" slot-scope="{ value }">
              <span> {{ new Date(value).toLocaleDateString() }}</span>
            </template>
          </ek-table>
        </b-col>
      </b-row>
      <year ref="yearDialog" />
      <semester ref="semesterDialog" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import year from "@admin/settings/components/AddYear";
import semester from "@admin/settings/components/AddSemester";

export default {
  components: {
    year,
    semester,
  },

  computed: {
    ...mapState({
      settingDto: ({ settings }) => settings.settingDto,
      dashDefaultValues: ({ settings }) => settings.dashDefaultValues,
      teacherDto: ({ settings }) => settings.teacherDto,
      isDialogOpen: (state) => state.settings.isDialogOpen,
      isDialog: (state) => state.settings.isDialog,
      teacherCols: ({ settings }) => settings.teacherCols,
      semesterList: ({ settings }) => settings.semesterList,
      semesterCols: ({ settings }) => settings.semesterCols,
      yearList: ({ settings }) => settings.yearList,
      yearCols: ({ settings }) => settings.yearCols,
    }),
  },
  methods: {
    ...mapActions([
      "getRate",
      "getSettings",
      "getSettingYear",
      "getSettingTeacher",
      "getSettingSemester",
      "updateRate",
      "addTeacher",
      "deleteYear",
      "deleteTeacher",
      "deleteSemester",
    ]),

    //update value rate
    submit() {
      this.$refs["form"].validate().then((suc) => {
        if (suc) {
          this.updateRate({
            sellPointRate: this.dashDefaultValues.sellPointRate,
            codePeriod: this.dashDefaultValues.codePeriod,
          });
        }
      });
    },

    //add & update teacher
    submitForm() {
      this.$refs["teacherForm"].validate().then((suc) => {
        if (suc) {
          if (this.teacherDto.password == null) {
            let password = "";
            this.teacherDto.password = password;
          }
          this.addTeacher(this.teacherDto);
          this.$refs.teacherForm.reset();
          this.$store.commit("Reset_Teacher_Dto");
          this.$refs.dialogTeacher.close();
        }
      });
    },

    //open dialog teacher
    teacherDetails({ row }) {
      this.$store.commit("IS_DIALOG", true);
      this.$store.commit("SET_TEACHER_DTO", row);
    },

    //open dialog years
    setYearDialogForm(item) {
      if (!item) {
        this.$store.commit("Set_Year_Dto");
      } else {
        this.$store.commit("Set_Year_Dto", item.row);
      }
      this.$refs.yearDialog.open();
    },

    //open dialog semesters
    setSemesterDialogForm(item) {
      if (!item) {
        this.$store.commit("Set_Semester_Dto");
      } else {
        this.$store.commit("Set_Semester_Dto", item.row);
      }
      this.$refs.semesterDialog.open();
    },

    //delete teacher
    deleteT(id) {
      this.$store.dispatch("deleteTeacher", [id]);
    },

    //reset dialog teacher
    reset() {
      this.$refs.teacherForm.reset();
      this.$store.commit("SET_TEACHER_DTO");
      this.$store.commit("IS_DIALOG", false);
      this.$refs.dialogTeacher.close();
    },
  },
  created() {
    this.getSettings();
    this.getRate();
    this.getSettingTeacher();
    this.getSettingYear();
    this.getSettingSemester();
  },
  watch: {
    isDialog(nv) {
      if (nv) {
        this.$refs.dialogTeacher.open();
      }
    },
  },
};
</script>
